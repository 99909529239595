import { UserType } from 'services/settings-service/settings.modal';

const headerDetails = [
  { label: 'name', cellSx: { pl: 2 } },
  { label: 'email' },
  { label: 'mobile' },
  { label: 'type' },
];

export const getUserTypeLabel = (userType: UserType): string => {
  switch (userType) {
    case UserType.operator:
      return 'users.operator';
    case UserType.user:
      return 'users.user';

    default:
      return '-';
  }
};

export default headerDetails;
