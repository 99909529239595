/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

export interface AppDrawerState {
  isOpen: boolean;
}

const initialState: AppDrawerState = {
  isOpen: true,
};

export const appDrawerSlice = createSlice({
  name: 'appDrawer',
  initialState,
  reducers: {
    setIsDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
  },
});

export const { setIsDrawerOpen } = appDrawerSlice.actions;

export const selectIsDrawerOpen = (state: RootState): boolean => state.appDrawer.isOpen;

export default appDrawerSlice.reducer;
