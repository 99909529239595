/* eslint-disable import/no-cycle */
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import appDrawer from './reducers/appDrawerSlice';
// eslint-disable-next-line import/no-named-as-default
import scannerSlice from './reducers/scannerSlice';
import user from './reducers/userSlice';

export const store = configureStore({
  reducer: {
    appDrawer,
    user,
    scannerSlice,
  },
});

export const persistor = store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
