/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserType } from 'services/settings-service/settings.modal';
import {
  INewUserDetails,
  IPinnedFields,
  IPinnedFieldsModule,
} from 'services/user-service/user.modal';

import { RootState } from '../store';

export interface UserState {
  permission: string[] | null;
  userType: UserType | null;
  userDetails: {
    organisation_id: string;
    userId: string;
  } | null;
  pinnedFields: IPinnedFields;
  loggedInUserDetails: INewUserDetails | null;
}

const initialState: UserState = {
  permission: null,
  userType: null,
  userDetails: null,
  pinnedFields: {
    asset: [],
    assetActivity: [],
    job: [],
    jobActivity: [],
    organisation: [],
    organisationLocation: [],
    part: [],
    user: [],
  },
  loggedInUserDetails: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserPermission: (state, action: PayloadAction<string[]>) => {
      state.permission = action.payload;
    },
    setUserType: (state, action: PayloadAction<UserType>) => {
      state.userType = action.payload;
    },
    setUserDetails: (state, action: PayloadAction<UserState['userDetails']>) => {
      state.userDetails = action.payload;
    },
    setLoggedInUserDetails: (state, action: PayloadAction<UserState['loggedInUserDetails']>) => {
      state.loggedInUserDetails = action.payload;
    },
    setPinnedFields: (state, action: PayloadAction<UserState['pinnedFields']>) => {
      state.pinnedFields = action.payload;
    },
    updatePinnedFields: (
      state,
      action: PayloadAction<{ module: IPinnedFieldsModule; data: string[] }>,
    ) => {
      const { module, data } = action.payload;

      const copy = {
        ...state.pinnedFields,
        [module]: data,
      };

      state.pinnedFields = copy;
    },
  },
});

export const getUserPermission = (state: RootState): string[] | null => state.user.permission;
export const getPinnedFields =
  (module: IPinnedFieldsModule | undefined) =>
  (state: RootState): string[] => {
    if (module) {
      return state.user.pinnedFields[module] ?? [];
    }
    return [];
  };

export const getPinnedData = (state: RootState): IPinnedFields => state.user.pinnedFields;
export const getCurrentUserType = (state: RootState): UserType | null => state.user.userType;

export const getCurrentUserDetails = (state: RootState): UserState['userDetails'] | null =>
  state.user.userDetails;

export const getCurrentLoggedInUserDetails = (
  state: RootState,
): UserState['loggedInUserDetails'] | null => state.user.loggedInUserDetails;

export default userSlice.reducer;
