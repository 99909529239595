import { makeStyles } from 'tss-react/mui';

import { remCalc } from 'theme/utils';

export const useStyle = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '24px',
    paddingLeft: '28px',
    width: '100%',
    height: '56px',
    background: '#101113',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      paddingRight: '16px',
      paddingLeft: '16px',
    },
    '&:hover': {
      '& > .nav-title': {
        background: '#525252',
        borderRadius: '6px',
      },
    },
  },
  titleContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    color: '#FFFFFF',
    height: '40px',
    padding: '4px',
    minWidth: '200px',
    textDecoration: 'none',
  },
  help: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    fontWeight: 400,
    height: '32px',
    width: '32px',
    background: '#424242',
    borderRadius: '6px',
  },
  menuHeaderSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '32px',
    width: '32px',
    borderRadius: '6px',
    background: '#424242',

    '& .MuiButtonBase-root': {
      marginLeft: 'unset',
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiTypography-root': {
      fontSize: remCalc(24),
      fontWeight: 500,
      lineHeight: '28.8px',
      color: theme.palette.primary.main,
    },
  },
}));
