import { Components, createTheme } from '@mui/material';

import { remCalc } from 'theme/utils';

const theme = createTheme({});

const ComponentOverrides: Components = {
  // MuiSwitch: {
  //   styleOverrides: {
  //     root: {
  //       width: 36,
  //       height: 20,
  //       padding: 0,
  //       margin: 8,
  //     },
  //     switchBase: {
  //       padding: 1,
  //       top: 1,
  //       left: 1,
  //       '&.Mui-checked': {
  //         left: '-3px',
  //         '& + .MuiSwitch-track': {
  //           border: '1px solid #202223',
  //           backgroundColor: '#202223',
  //         },
  //       },
  //       '&$checked, &$colorPrimary$checked, &$colorSecondary$checked': {
  //         transform: 'translateX(16px)',
  //         color: '#fff !important',
  //         '& + $track': {
  //           opacity: 1,
  //           border: 'none',
  //         },
  //       },
  //       '&$checked': {
  //         color: '#fff !important',
  //       },
  //     },
  //     thumb: {
  //       width: 16,
  //       height: 16,
  //       color: '#fff !important',
  //     },
  //     track: {
  //       borderRadius: 12,
  //       border: '1px solid #F2F4F7',
  //       backgroundColor: '#F2F4F7',
  //       opacity: '1 !important',
  //       transition:
  //         'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  //     },
  //   },
  // },
  MuiTooltip: {
    defaultProps: {
      arrow: true,
    },
  },

  MuiInputLabel: {
    styleOverrides: {
      root: {
        lineHeight: '20px',
        fontSize: remCalc(18),
        color: '#1E1E20',
        marginBottom: '10px',
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        padding: '18px 24px 18px 24px',

        minHeight: '48px',
        '& .MuiAccordionSummary-content': {
          margin: 0,
          fontSize: remCalc(14),
          lineHeight: '20px',
          alignItems: 'center',
          [theme.breakpoints.down('md')]: {
            alignItems: 'flex-start',
          },
          '&.Mui-expanded': {
            margin: 0,
          },
        },
        '&.MuiButtonBase-root.Mui-expanded': {
          minHeight: '48px',
        },
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: '0px 24px 24px',
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        borderRadius: '0 !important',
        boxShadow: 'none',
        borderWidth: '1px 0px',
        borderStyle: 'solid',
        borderColor: '#D6D6D6',
        '&.Mui-expanded': {
          margin: 0,
        },
        '&:before': {
          display: 'none',
        },
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        fontSize: remCalc(12),
        color: '#141414',
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      fullWidth: true,
      InputProps: {
        disableUnderline: true,
      },
      InputLabelProps: {
        shrink: true,
      },
      variant: 'standard',
    },
    styleOverrides: {
      root: {
        '&.mobile': {
          width: '100%',
          minWidth: 'auto',
          [theme.breakpoints.up('md')]: {
            width: 'unset',
            minWidth: '300px',
          },
        },

        padding: '0 0 0 0',
        '& legend': {
          width: 0,
        },
        width: '100%',
        '& .MuiInputLabel-root': {
          color: '#1E1E20',
          fontWeight: 'normal',
          fontSize: remCalc(18),
          lineHeight: '21px',
          '&$formLabelFocused': {
            color: '#1E1E20',
          },
          transform: 'none',
        },
        '& .MuiInputBase-root': {
          minHeight: '36px',
          borderRadius: 4,
          backgroundColor: '#ffffff',
          border: '1px solid #D6D6D6',
          fontSize: remCalc(14),
          padding: '0px 12px 0px 12px',
          gap: '5px',
          marginTop: '0',
          '&:focus': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'none',
            },
          },
        },
        '&::before': {
          borderBottom: 'none',
        },
        '& .MuiInputBase-multiline': {
          height: 'auto',
          padding: '6px 12px 0px 12px',
        },
        'label + &': {
          // marginBottom: 5,
        },
      },
    },
  },
  MuiTablePagination: {
    styleOverrides: {
      select: {
        paddingBottom: 'initial',
        paddingTop: 'initial',
        minHeight: 'initial',
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        boxShadow:
          '0px 3px 6px -3px rgba(23, 24, 24, 0.08), 0px 8px 20px -4px rgba(23, 24, 24, 0.12)',
        borderRadius: '4px',
        border: '1px solid #E5E5E5',
      },
      list: {
        padding: 0,
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        borderBottom: '1px solid #E5E5E5',
        '&:last-child': {
          borderBottom: 'none',
        },
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        '&.MuiTablePagination-toolbar': {
          '& .MuiInputBase-root': {
            '& .MuiTablePagination-select': {
              minHeight: 'initial',
            },
          },
        },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        paddingTop: '8px',
        paddingBottom: '8px',
        minHeight: '32px',
      },
      outlined: {
        paddingTop: '12px',
        paddingBottom: '4px',
        minHeight: '32px',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        height: '36px',
        fontWeight: 400,
        fontSize: remCalc(14),
        lineHeight: '20px',
        textTransform: 'initial',
        boxShadow: 'none',
      },
      outlinedPrimary: {
        border: '1px solid  #D6D6D6',
        color: ' #737373',
        '&:hover': {
          border: '1px solid  #D6D6D6',
        },
        '&:disabled , &.Mui-disabled': {
          '& svg': {
            opacity: 0.2,
          },
        },
      },
      containedPrimary: {
        '&.mobile': {
          width: '100%',
          [theme.breakpoints.up('md')]: {
            width: 'auto',
          },
        },
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: '24px',
      },
    },
  },

  MuiButtonGroup: {
    styleOverrides: {
      grouped: {
        minWidth: '70px',
      },
    },
  },

  MuiAutocomplete: {
    styleOverrides: {
      noOptions: {
        fontSize: remCalc(14),
      },
      loading: {
        fontSize: remCalc(14),
      },
      root: {
        '& .MuiFormControl-root.MuiTextField-root': {
          '& .MuiInputBase-input': {
            height: 'initial !important',
            padding: 0,
          },
          '& .MuiAutocomplete-tag': {
            margin: 0,
            marginRight: '8px',
            fontSize: remCalc(12),
            background: '#F2F4F7',
            height: '20px',
            borderRadius: '2px',
            padding: '2px 8px',
          },
          '& span': {
            fontWeight: 600,
            color: '#141414',
            paddingLeft: 0,
            paddingRight: '15px',
          },
          '& .MuiAutocomplete-endAdornment': {
            paddingRight: '15px',
            paddingTop: '3px',
          },
          '& .MuiInputBase-root': {
            padding: '8px 50px 8px 12px',
            '&.Mui-focused': {
              '& .MuiAutocomplete-endAdornment': {
                paddingTop: '0',
              },
            },
            height: 'unset',
            minHeight: '36px',
            '&::before': {
              borderBottom: 'none',
            },
            '&::after': {
              borderBottom: 'none',
            },
          },
        },
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: '24px',
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: '16px 24px',
        [theme.breakpoints.down('md')]: {
          justifyContent: 'space-between',
        },
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        '&:first-letter': {
          textTransform: 'capitalize',
        },
        color: '#737373',
        lineHeight: '20px',
        marginBottom: '10px',
        fontSize: remCalc(14),
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        padding: '18px 16px',
      },
      head: {
        padding: '10px 16px',
        '& .header-cell-content': {
          display: 'flex',
          alignItems: 'center',
          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
        },
      },
      body: {
        [theme.breakpoints.down('md')]: {
          display: 'block',
        },
      },
    },
  },
  MuiTableFooter: {
    styleOverrides: {
      root: {
        left: 0,
        bottom: 0,
        zIndex: 2,
        position: 'sticky',
        background: '#fff',
        boxShadow: '0px 15px 25px rgb(0 0 0 / 5%)',
      },
    },
  },
  MuiPagination: {
    styleOverrides: {
      ul: {
        '& li': {
          '&:first-child': {
            marginRight: 'auto',
            '& .MuiPaginationItem-root': {
              height: '24px',
              width: '24px',
              minWidth: 'unset',
              border: ' 1px solid #D6D6D6',
              boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.05)',
              color: theme.palette.grey[500],
            },
          },
          '&:last-child': {
            marginLeft: 'auto',
            '& .MuiPaginationItem-root': {
              height: '24px',
              width: '24px',
              minWidth: 'unset',
              border: ' 1px solid #D6D6D6',
              boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.05)',
              color: theme.palette.grey[500],
            },
          },
        },
      },
    },
  },
  MuiPaginationItem: {
    styleOverrides: {
      outlined: {
        height: '40px',
        width: '40px',
        borderRadius: '8px',
        border: 'none',

        '&.Mui-selected': {
          background: '#F5F5F5',
          color: '#141414',
          border: 'none',
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        padding: '8px 0px 8px 12px',
        borderColor: 'red',
        '& .MuiOutlinedInput-input': {
          padding: '0px',
          '&::placeholder': {
            textOverflow: 'ellipsis !important',
            color: '#1E1E20',
            opacity: 1,
          },
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: '1px solid #E6E6E6',
        },
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      outlinedInfo: {
        border: '2px solid #0A78C1',
        borderRadius: '6px',
        fontSize: remCalc(16),
      },
      standardSuccess: {
        background: '#D6F7C4',
        borderRadius: '0px',
        '& .MuiAlert-message': {
          color: '#4BB00D',
          fontWeight: 600,
        },
        '& .MuiAlert-icon': {
          opacity: 1,
          padding: 0,
        },
      },
      message: {
        fontSize: '14px',
      },
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        '& path': {
          fill: '#D6D6D6',
        },
        '&:hover': {
          backgroundColor: 'unset',
        },
        '&.Mui-checked': {
          '& path': {
            fill: '#141414',
          },
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        '& .MuiChip-label': {
          paddingRight: '8px',
        },
      },
      colorPrimary: {
        background: '#CEE4F3',
        color: '#1E1E20',

        '& .MuiChip-deleteIcon': {
          margin: 0,
        },
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      input: {
        '&::placeholder': {
          color: '#737373',
          opacity: 1,
          fontSize: remCalc(14),
        },
        color: '#101113',
      },
      root: {
        '&::before': {
          borderBottom: 'none !important',
        },
        '&::after': {
          borderBottom: 'none !important',
        },
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15)',
        borderRadius: '6px',
        border: '1px solid #D6D6D6',
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          background: '#F5F5F5',
          border: '1px solid #BCBBBC',
          '& img': {
            opacity: 0.4,
          },
        },
        '&.Mui-focused': {
          border: '1px solid #141414',
        },
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        padding: '20px 24px',
        paddingRight: '50px',
        lineHeight: '24px',
        fontWeight: 400,
        fontSize: remCalc(20),
        // eslint-disable-next-line quotes
        fontFamily: "'Apfel Grotezk', sans-serif",
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        display: 'block',
        [theme.breakpoints.up('md')]: {
          minWidth: '400px',
        },
      },
    },
  },
};

export default ComponentOverrides;
