/* eslint-disable import/no-cycle */
import { APIS } from '../../constants/api.constants';
import { BaseApi } from '../common/base-api';
import { processResponse } from '../common/common';
import { INewUserDetails, IPinnedFields } from './user.modal';

const getLoggedInUserDetails = async (): Promise<Error | INewUserDetails> => {
  const response = await BaseApi.get(APIS.SETTINGS.ME);
  return processResponse<INewUserDetails>(response);
};

const updatePinnedFields = async (data: IPinnedFields): Promise<Error | INewUserDetails> => {
  const response = await BaseApi.patch(`${APIS.SETTINGS.ME}`, {
    pinnedFields: data,
  });
  return processResponse<INewUserDetails>(response);
};

export { getLoggedInUserDetails, updatePinnedFields };
